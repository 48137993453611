.main-banner{
        background-color: $light-green;
        margin: 2em auto 0 auto;
        width: 100%;

        @include media-query(kilo){
            background-image: url(/static/images/banner-home.png);
            background-repeat: no-repeat; //May be a slight hack but will only notice on 3600px and above.
            background-position: center;
            margin-top: 2em;
            margin: 2em auto 0 auto;
            width: 100%;
            padding-top: 24%;
            position: relative;
            background-size: 100%;
    }
        .container-row{
            max-width: 1400px;
            margin: 0 auto;
            padding: 0;
            position: relative;
            display: inline-block;

            @include media-query(kilo){
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                height: 3rem;
                margin: auto;
            }

            .banner-left{
                display: none;

                @include media-query(micro){
                    display: block;
                    border-right: 5px solid $light-green;
                    border-left: 5px solid $light-green;
                    float: left;
                    max-width: calc(40% - 6px);
                }
                @include media-query(kilo){
                    display: none;
                }
            }

            .banner-right{
                border: none;
                margin: 0 auto;

                @include media-query(micro){
                    border-right: 5px solid $light-green;
                    float: left;
                    max-width: calc(60% - 11px);
                }

                @include media-query(kilo){
                    display: none;
                }

            }

            h1{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                font-family: $sec-body;
                font-size: 1.2em;
                font-style: italic;
                text-transform: uppercase;
                background-color: rgba(0,0,0,0.3);
                max-width: 740px;
                width: 100%;
                margin: 0;

                @include media-query(pico){
                    font-size: 1.5em;
                }

                @include media-query(nano){
                    font-size: 2em;
                }

                @include media-query(kilo){
                    position: static;
                    margin: 0 auto;
                    transform: none;
                    left: auto;
                    text-align: center;
                    font-size: 2em;
                    max-width: 815px;
                    width: auto;
                    height: auto;
                    line-height: 1;
                    padding: 7px;
                }
            }
    }
}

