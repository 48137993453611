@import url("https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed");
@import url("https://fonts.googleapis.com/css?family=Montserrat");
.size {
  display: block;
  position: fixed;
  top: 30px;
  left: 30px;
  height: 20px;
  width: 20px;
  background-color: orange;
  z-index: 500;
}
@media all and (min-width: 320px) {
  .size {
    background-color: yellow;
  }
}
@media all and (min-width: 480px) {
  .size {
    background-color: green;
  }
}
@media all and (min-width: 640px) {
  .size {
    background-color: blue;
  }
}
@media all and (min-width: 885px) {
  .size {
    background-color: red;
  }
}
@media all and (min-width: 962px) {
  .size {
    background-color: cyan;
  }
}
@media all and (min-width: 1120px) {
  .size {
    background-color: violet;
  }
}
@media all and (min-width: 1280px) {
  .size {
    background-color: pink;
  }
}
@media all and (min-width: 1440px) {
  .size {
    background-color: CORAL;
  }
}
@media all and (min-width: 1600px) {
  .size {
    background-color: ROSYBROWN;
  }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

figure {
  margin: 0;
}

body {
  color: #fff;
  line-height: 1.5;
  margin: 0;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}

a {
  color: #fff;
  font-family: "Montserrat", sans-serif;
}

a:focus {
  outline: 1px dotted;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
  line-height: 1.25;
  margin: 1em 0 0 0;
  page-break-after: avoid;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.25em;
}

hr {
  border: 0;
  border-top: 1px solid;
  margin: 1em 0;
}

blockquote {
  margin: 1em 2em;
}

p {
  margin: 1em 0;
  font-family: "Montserrat", sans-serif;
}

pre {
  margin: 1em 0;
  white-space: pre-wrap;
}

address,
cite,
dfn,
em,
i,
var {
  font-style: italic;
}

b,
strong {
  font-weight: bold;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 0.875em;
}

pre code,
pre kbd,
pre samp {
  font-size: 1em;
}

small {
  font-size: 0.75em;
}

sub,
sup {
  font-size: 0.75em;
  line-height: 0;
}

sub {
  vertical-align: sub;
}

sup {
  vertical-align: super;
}

dl {
  margin: 1em 0;
}

dd {
  margin-left: 2em;
}

ol,
ul {
  margin: 1em 0;
  padding-left: 2em;
}

ol li {
  list-style: decimal outside none;
}

ol li li {
  list-style: lower-alpha outside none;
}

ol li li li {
  list-style: lower-roman outside none;
}

ul li {
  list-style: disc outside none;
}

ul li li {
  list-style: circle outside none;
}

img {
  border: 0;
  height: auto;
  max-width: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 1em auto;
}

td,
th {
  padding: 0.25em 0.5em;
  text-align: left;
}

.header {
  width: 100%;
  background-color: #fff;
  margin-top: 0.5em;
}
.header .container-row {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 3%;
  display: block;
}
@media all and (min-width: 962px) {
  .header .container-row {
    padding: 0 1%;
  }
}
@media all and (min-width: 962px) {
  .header .container-row .heading {
    float: left;
  }
}
.header .container-row .heading h1 {
  margin: 0;
}
.header .container-row .heading h1 a img {
  margin: 0 auto;
  display: block;
}
@media all and (min-width: 640px) {
  .header .container-row .heading h1 a img {
    margin: 0;
  }
}
.header .container-row .contact-info {
  display: none;
}
@media all and (min-width: 480px) {
  .header .container-row .contact-info {
    margin: 0 auto;
    display: table;
  }
}
@media all and (min-width: 640px) {
  .header .container-row .contact-info {
    margin: 0;
    display: block;
    width: 100%;
  }
}
@media all and (min-width: 962px) {
  .header .container-row .contact-info {
    float: left;
    margin-top: 4.2em;
    width: auto;
  }
}
@media all and (min-width: 1280px) {
  .header .container-row .contact-info {
    margin-left: 2em;
  }
}
@media all and (min-width: 1440px) {
  .header .container-row .contact-info {
    margin-left: 5em;
  }
}
@media all and (min-width: 1600px) {
  .header .container-row .contact-info {
    margin-left: 5em;
  }
}
.header .container-row .contact-info a {
  color: #000;
  display: block;
  text-align: center;
  padding: 0.5em 0;
  text-decoration: none;
  width: 100%;
}
.header .container-row .contact-info a:hover {
  font-weight: 600;
}
@media all and (min-width: 480px) {
  .header .container-row .contact-info a {
    display: table-cell;
    vertical-align: middle;
    width: 33%;
  }
}
@media all and (min-width: 640px) {
  .header .container-row .contact-info a {
    display: inline-block;
    margin-right: 2em;
    width: auto;
  }
}
@media all and (min-width: 1120px) {
  .header .container-row .contact-info a {
    margin-right: 2em;
  }
}
@media all and (min-width: 1600px) {
  .header .container-row .contact-info a {
    margin-right: 5em;
  }
}
.header .container-row .header-logos {
  text-align: center;
  margin-top: 1em;
}
@media all and (min-width: 480px) {
  .header .container-row .header-logos {
    margin: 0;
    padding-bottom: 0.5em;
  }
}
@media all and (min-width: 640px) {
  .header .container-row .header-logos {
    position: absolute;
    top: 50px;
    left: 350px;
  }
}
@media all and (min-width: 962px) {
  .header .container-row .header-logos {
    top: 57px;
    left: 766px;
  }
}
@media all and (min-width: 1120px) {
  .header .container-row .header-logos {
    left: 862px;
  }
}
@media all and (min-width: 1280px) {
  .header .container-row .header-logos {
    left: 900px;
  }
}
@media all and (min-width: 1440px) {
  .header .container-row .header-logos {
    top: 49px;
    left: 906px;
  }
}
@media all and (min-width: 1600px) {
  .header .container-row .header-logos {
    top: 45px;
    left: 983px;
  }
}
.header .container-row .header-logos a img {
  display: inline-block;
  vertical-align: middle;
}
.header .container-row .header-logos a .which-header {
  max-width: 20%;
}
@media all and (min-width: 640px) {
  .header .container-row .header-logos a .which-header {
    max-width: 29%;
  }
}
@media all and (min-width: 1440px) {
  .header .container-row .header-logos a {
    padding-right: 2em;
  }
}
@media all and (min-width: 1600px) {
  .header .container-row .header-logos a {
    padding-right: 3em;
  }
}
.header .container-row .showroom-box {
  display: none;
}
@media all and (min-width: 1120px) {
  .header .container-row .showroom-box {
    width: 140px;
    border: 1px solid #000;
    display: block;
    position: absolute;
    left: 1030px;
    top: 36px;
    text-align: center;
  }
}
@media all and (min-width: 1280px) {
  .header .container-row .showroom-box {
    left: 1100px;
  }
}
@media all and (min-width: 1440px) {
  .header .container-row .showroom-box {
    left: 1200px;
  }
}
@media all and (min-width: 1600px) {
  .header .container-row .showroom-box {
    left: 1270px;
    top: 43px;
  }
}
.header .container-row .showroom-box p {
  color: #000;
  margin: 0;
  padding-top: 0.5em;
}
@media all and (min-width: 1600px) {
  .header .container-row .showroom-box p {
    padding-top: 0.2em;
  }
}
.header .container-row .showroom-box .showroom-times {
  font-size: 0.9em;
  padding: 0.2em 1em 0 1em;
}
.header .container-row .showroom-box .showroom-visit {
  color: #33a643;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.header .container-row .showroom-box .showroom-visit .showroom-york {
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
}
.header .container-row .mobile-nav {
  background-image: url(/static/images/mob-menu.png);
  background-position: right 50%;
  background-repeat: no-repeat;
  background-size: 50px;
  border-top: 2px solid #33a643;
  font-size: 1.2rem;
  width: 100%;
  height: 50px;
  margin-top: 1em;
}
@media all and (min-width: 480px) {
  .header .container-row .mobile-nav {
    margin: 0;
  }
}
@media all and (min-width: 640px) {
  .header .container-row .mobile-nav {
    display: none;
    height: 0;
  }
}
@media all and (min-width: 885px) {
  .header .container-row .nav {
    float: left;
  }
}
@media all and (min-width: 1120px) {
  .header .container-row .nav {
    width: 70%;
  }
}
@media all and (min-width: 1280px) {
  .header .container-row .nav {
    margin-left: 2em;
  }
}
@media all and (min-width: 1440px) {
  .header .container-row .nav {
    margin-left: 5em;
  }
}
@media all and (min-width: 1600px) {
  .header .container-row .nav {
    margin-left: 5em;
  }
}
.header .container-row .nav .nav-wrap {
  position: relative;
  z-index: 100;
}
.header .container-row .nav .nav-wrap ul {
  display: block;
  list-style-type: none;
  text-align: center;
  margin: 0;
  padding: 0;
}
.header .container-row .nav .nav-wrap ul li {
  list-style: none;
}
@media all and (min-width: 640px) {
  .header .container-row .nav .nav-wrap ul {
    display: table;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }
}
.header .container-row .nav .nav-wrap ul .has-children .dropdown {
  display: none;
  position: relative;
  background-color: #fff;
}
@media all and (min-width: 640px) {
  .header .container-row .nav .nav-wrap ul .has-children .dropdown {
    max-width: 190px;
    position: absolute;
  }
}
@media all and (min-width: 640px) {
  .header .container-row .nav .nav-wrap ul .has-children .dropdown ul {
    display: inline-block;
    border: 1px solid #000;
    border-top: none;
  }
}
.header .container-row .nav .nav-wrap ul .has-children .dropdown ul li {
  display: inline-block;
  width: 100%;
  background-color: #fff;
  list-style: none;
}
@media all and (min-width: 640px) {
  .header .container-row .nav .nav-wrap ul .has-children .dropdown ul li {
    float: none;
    margin: 0;
    padding: 0;
    text-align: left;
    padding: 0.25em 0.5em;
    width: 90%;
  }
}
.header .container-row .nav .nav-wrap ul .has-children .dropdown ul li:hover {
  background-color: #33a643;
}
@media all and (min-width: 640px) {
  .header .container-row .nav .nav-wrap ul .has-children .dropdown ul li:hover {
    background-color: #fff;
  }
}
.header .container-row .nav .nav-wrap ul .has-children .dropdown ul li:hover .dropdown {
  background-color: #33a643;
}
.header .container-row .nav .nav-wrap ul .has-children .dropdown ul li:hover a {
  color: #fff;
}
@media all and (min-width: 640px) {
  .header .container-row .nav .nav-wrap ul .has-children .dropdown ul li:hover a {
    color: #000;
    font-weight: 600;
  }
}
.header .container-row .nav .nav-wrap ul .has-children .dropdown ul li a {
  color: #000;
}
.header .container-row .nav .nav-wrap ul .has-children:hover .dropdown {
  display: block;
}
.header .container-row .nav .nav-wrap ul li {
  background-color: #fff;
  display: block;
  list-style-type: none;
  padding: 0.25em 0;
  width: 100%;
}
@media all and (min-width: 640px) {
  .header .container-row .nav .nav-wrap ul li:last-child {
    padding-right: 0;
  }
}
@media all and (min-width: 640px) {
  .header .container-row .nav .nav-wrap ul li {
    background-color: transparent;
    float: left;
    padding-right: 1em;
    display: table-cell;
    width: auto;
  }
}
@media all and (min-width: 885px) {
  .header .container-row .nav .nav-wrap ul li {
    padding-right: 1em;
  }
}
@media all and (min-width: 962px) {
  .header .container-row .nav .nav-wrap ul li {
    padding-right: 1em;
  }
}
@media all and (min-width: 1120px) {
  .header .container-row .nav .nav-wrap ul li {
    padding-right: 2em;
  }
}
@media all and (min-width: 1600px) {
  .header .container-row .nav .nav-wrap ul li {
    padding-right: 3em;
  }
}
.header .container-row .nav .nav-wrap ul li a {
  color: #000;
  display: block;
  width: 100%;
  text-decoration: none;
}
@media all and (min-width: 640px) {
  .header .container-row .nav .nav-wrap ul li a {
    width: auto;
  }
}
.header .container-row .nav .nav-wrap ul li:hover {
  background-color: #33a643;
  color: #fff;
}
.header .container-row .nav .nav-wrap ul li:hover a {
  color: #fff;
}
@media all and (min-width: 640px) {
  .header .container-row .nav .nav-wrap ul li:hover a {
    color: #000;
    font-weight: 600;
  }
}
@media all and (min-width: 640px) {
  .header .container-row .nav .nav-wrap ul li:hover {
    background-color: transparent;
    color: #000;
  }
}
.header .container-row .nav .nav-wrap ul .active {
  background-color: #33a643;
}
.header .container-row .nav .nav-wrap ul .active a {
  color: #fff;
}
@media all and (min-width: 640px) {
  .header .container-row .nav .nav-wrap ul .active a {
    color: #000;
  }
}
@media all and (min-width: 640px) {
  .header .container-row .nav .nav-wrap ul .active {
    background-color: transparent;
    font-weight: 600;
  }
}
.header .container-row .menu-text {
  color: #000;
  font-size: 1.2em;
  text-align: center;
  padding: 0.4em 0;
  margin: 0;
  text-transform: uppercase;
  cursor: pointer;
}
@media all and (min-width: 640px) {
  .header .container-row .menu-text {
    display: none;
  }
}
.header:after {
  content: "";
  display: block;
  clear: both;
}

.-closed {
  display: none;
}

.main-banner {
  background-color: #30cd2f;
  margin: 2em auto 0 auto;
  width: 100%;
}
@media all and (min-width: 1120px) {
  .main-banner {
    background-image: url(/static/images/banner-home.png);
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 2em;
    margin: 2em auto 0 auto;
    width: 100%;
    padding-top: 24%;
    position: relative;
    background-size: 100%;
  }
}
.main-banner .container-row {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0;
  position: relative;
  display: inline-block;
}
@media all and (min-width: 1120px) {
  .main-banner .container-row {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 3rem;
    margin: auto;
  }
}
.main-banner .container-row .banner-left {
  display: none;
}
@media all and (min-width: 640px) {
  .main-banner .container-row .banner-left {
    display: block;
    border-right: 5px solid #30cd2f;
    border-left: 5px solid #30cd2f;
    float: left;
    max-width: calc(40% - 6px);
  }
}
@media all and (min-width: 1120px) {
  .main-banner .container-row .banner-left {
    display: none;
  }
}
.main-banner .container-row .banner-right {
  border: none;
  margin: 0 auto;
}
@media all and (min-width: 640px) {
  .main-banner .container-row .banner-right {
    border-right: 5px solid #30cd2f;
    float: left;
    max-width: calc(60% - 11px);
  }
}
@media all and (min-width: 1120px) {
  .main-banner .container-row .banner-right {
    display: none;
  }
}
.main-banner .container-row h1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: "Barlow Semi Condensed";
  font-size: 1.2em;
  font-style: italic;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0.3);
  max-width: 740px;
  width: 100%;
  margin: 0;
}
@media all and (min-width: 320px) {
  .main-banner .container-row h1 {
    font-size: 1.5em;
  }
}
@media all and (min-width: 480px) {
  .main-banner .container-row h1 {
    font-size: 2em;
  }
}
@media all and (min-width: 1120px) {
  .main-banner .container-row h1 {
    position: static;
    margin: 0 auto;
    transform: none;
    left: auto;
    text-align: center;
    font-size: 2em;
    max-width: 815px;
    width: auto;
    height: auto;
    line-height: 1;
    padding: 7px;
  }
}

.landing-banner .container-row {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0;
  position: relative;
  display: inline-block;
}
@media all and (min-width: 1120px) {
  .landing-banner .container-row {
    padding: 13em 3%;
    display: flex !important;
  }
}
@media all and (min-width: 1120px) {
  .landing-banner .banner-text {
    max-width: 740px !important;
  }
}

.inner-banner {
  margin-top: 0;
}
.inner-banner .container-row {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0;
  position: relative;
  display: inline-block;
}
@media all and (min-width: 1120px) {
  .inner-banner .container-row {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 3rem;
    margin: auto;
  }
}
.inner-banner .banner-text {
  line-height: 1;
  height: 1em;
  max-width: 740px !important;
}

.inner-crumbtrail {
  background-color: #ccffcc;
  margin-top: 2em;
}
.inner-crumbtrail .container-row {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0;
}
.inner-crumbtrail .container-row .breadcrumb {
  color: #336633;
  margin: 0;
  padding: 0.5em 0em 0.5em 1em;
  font-size: 0.8em;
  font-weight: 600;
}
@media all and (min-width: 1440px) {
  .inner-crumbtrail .container-row .breadcrumb {
    padding: 0.5em 0em;
  }
}
.inner-crumbtrail .container-row .breadcrumb a {
  color: #336633;
  text-decoration: none;
}

.alum-wind {
  background-image: none;
}
@media all and (min-width: 480px) {
  .alum-wind {
    background-image: url(/static/images/banner-alum-doors.png);
    background-repeat: space;
    background-position: center;
  }
}
.alum-wind .container-row {
  padding: 0;
  display: inline-block;
}
@media all and (min-width: 480px) {
  .alum-wind .container-row {
    padding: 11em 3%;
    display: block;
  }
}
@media all and (min-width: 1120px) {
  .alum-wind .container-row {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 6rem;
    margin: auto;
  }
}
@media all and (min-width: 480px) {
  .alum-wind .container-row .banner-right {
    display: none;
  }
}
@media all and (min-width: 1120px) {
  .alum-wind .container-row .banner-text {
    max-width: 760px !important;
  }
}

.pvcu {
  background-image: none;
}
@media all and (min-width: 480px) {
  .pvcu {
    background-image: url(/static/images/banner-pvcu-windows.png);
    background-repeat: space;
    background-position: center;
  }
}
.pvcu .container-row {
  padding: 0;
  display: inline-block;
}
@media all and (min-width: 480px) {
  .pvcu .container-row {
    padding: 11em 3%;
    display: block;
  }
}
@media all and (min-width: 1120px) {
  .pvcu .container-row {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 6rem;
    margin: auto;
  }
}
@media all and (min-width: 480px) {
  .pvcu .container-row .banner-right {
    display: none;
  }
}

.trad-pvcu {
  background-image: none;
}
@media all and (min-width: 480px) {
  .trad-pvcu {
    background-image: url(/static/images/banner-traditional-pvcs.png);
    background-repeat: space;
    background-position: center;
  }
}
.trad-pvcu .container-row {
  padding: 0;
  display: inline-block;
}
@media all and (min-width: 480px) {
  .trad-pvcu .container-row {
    padding: 11em 3%;
    display: block;
  }
}
@media all and (min-width: 1120px) {
  .trad-pvcu .container-row {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 6rem;
    margin: auto;
  }
}
@media all and (min-width: 480px) {
  .trad-pvcu .container-row .banner-right {
    display: none;
  }
}

.alum-door {
  background-image: none;
}
@media all and (min-width: 480px) {
  .alum-door {
    background-image: url(/static/images/banner-alum-doors.png);
    background-repeat: space;
    background-position: center;
  }
}
.alum-door .container-row {
  padding: 0;
  display: inline-block;
}
@media all and (min-width: 480px) {
  .alum-door .container-row {
    padding: 11em 3%;
    display: block;
  }
}
@media all and (min-width: 1120px) {
  .alum-door .container-row {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 6rem;
    margin: auto;
  }
}
@media all and (min-width: 480px) {
  .alum-door .container-row .banner-right {
    display: none;
  }
}

.timb-wind {
  background-image: none;
}
@media all and (min-width: 480px) {
  .timb-wind {
    background-image: url(/static/images/banner-timb-wind.png);
    background-repeat: space;
    background-position: center;
    padding-top: 22%;
  }
}
.timb-wind .container-row {
  padding: 0;
  display: inline-block;
}
@media all and (min-width: 480px) {
  .timb-wind .container-row {
    padding: 11em 3%;
    display: block;
  }
}
@media all and (min-width: 1120px) {
  .timb-wind .container-row {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 6rem;
    margin: auto;
  }
}
@media all and (min-width: 480px) {
  .timb-wind .container-row .banner-right {
    display: none;
  }
}

.timb-door {
  background-image: none;
}
@media all and (min-width: 480px) {
  .timb-door {
    background-image: url(/static/images/banner-timber-doors.png);
    background-repeat: space;
    background-position: center;
  }
}
.timb-door .container-row {
  padding: 0;
  display: inline-block;
}
@media all and (min-width: 480px) {
  .timb-door .container-row {
    padding: 11em 3%;
    display: block;
  }
}
@media all and (min-width: 1120px) {
  .timb-door .container-row {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 6rem;
    margin: auto;
  }
}
@media all and (min-width: 480px) {
  .timb-door .container-row .banner-right {
    display: none;
  }
}

.door-main {
  background-image: none;
}
@media all and (min-width: 480px) {
  .door-main {
    background-image: url(/static/images/banner-wind-main.png);
    background-repeat: space;
    background-position: center;
  }
}
.door-main .container-row {
  padding: 0;
  display: inline-block;
}
@media all and (min-width: 480px) {
  .door-main .container-row {
    padding: 11em 3%;
    display: block;
  }
}
@media all and (min-width: 1120px) {
  .door-main .container-row {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 6rem;
    margin: auto;
  }
}
@media all and (min-width: 480px) {
  .door-main .container-row .banner-right {
    display: none;
  }
}
@media all and (min-width: 1120px) {
  .door-main .container-row .banner-text {
    height: 2em;
    max-width: 760px !important;
  }
}

.comp-doors {
  background-image: none;
}
@media all and (min-width: 480px) {
  .comp-doors {
    background-image: url(/static/images/banner-composite-doors.png);
    background-repeat: space;
    background-position: center;
  }
}
.comp-doors .container-row {
  padding: 0;
  display: inline-block;
}
@media all and (min-width: 480px) {
  .comp-doors .container-row {
    padding: 11em 3%;
    display: block;
  }
}
@media all and (min-width: 1120px) {
  .comp-doors .container-row {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 6rem;
    margin: auto;
  }
}
@media all and (min-width: 480px) {
  .comp-doors .container-row .banner-right {
    display: none;
  }
}

.roof-banner {
  background-image: none;
}
@media all and (min-width: 480px) {
  .roof-banner {
    background-image: url(/static/images/banner-roofline.png);
    background-repeat: space;
    background-position: center;
  }
}
.roof-banner .container-row {
  padding: 0;
  display: inline-block;
}
@media all and (min-width: 480px) {
  .roof-banner .container-row {
    padding: 11em 3%;
    display: block;
  }
}
@media all and (min-width: 1120px) {
  .roof-banner .container-row {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 3rem;
    margin: auto;
  }
}
@media all and (min-width: 480px) {
  .roof-banner .container-row .banner-right {
    display: none;
  }
}

.conser-banner {
  background-image: none;
}
@media all and (min-width: 480px) {
  .conser-banner {
    background-image: url(/static/images/banner-conser.png);
    background-repeat: space;
    background-position: center;
  }
}
.conser-banner .container-row {
  padding: 0;
  display: inline-block;
}
@media all and (min-width: 480px) {
  .conser-banner .container-row {
    padding: 11em 3%;
    display: block;
  }
}
@media all and (min-width: 1120px) {
  .conser-banner .container-row {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 3rem;
    margin: auto;
  }
}
@media all and (min-width: 480px) {
  .conser-banner .container-row .banner-right {
    display: none;
  }
}
@media all and (min-width: 1120px) {
  .conser-banner .container-row .banner-text {
    max-width: 790px !important;
  }
}

.pvcu-door {
  background-image: none;
}
@media all and (min-width: 480px) {
  .pvcu-door {
    background-image: url(/static/images/banner-pvcu-doors.png);
    background-repeat: space;
    background-position: center;
  }
}
.pvcu-door .container-row {
  padding: 0;
  display: inline-block;
}
@media all and (min-width: 480px) {
  .pvcu-door .container-row {
    padding: 11em 3%;
    display: block;
  }
}
@media all and (min-width: 1120px) {
  .pvcu-door .container-row {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 6rem;
    margin: auto;
  }
}
@media all and (min-width: 480px) {
  .pvcu-door .container-row .banner-right {
    display: none;
  }
}

.wind-main {
  background-image: none;
}
@media all and (min-width: 480px) {
  .wind-main {
    background-image: url(/static/images/banner-doors-main.png);
    background-repeat: space;
    background-position: center;
  }
}
.wind-main .container-row {
  padding: 0;
  display: inline-block;
}
@media all and (min-width: 480px) {
  .wind-main .container-row {
    padding: 11em 3%;
    display: block;
  }
}
@media all and (min-width: 1120px) {
  .wind-main .container-row {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 6rem;
    margin: auto;
  }
}
@media all and (min-width: 480px) {
  .wind-main .container-row .banner-right {
    display: none;
  }
}
@media all and (min-width: 1120px) {
  .wind-main .container-row .banner-text {
    line-height: 1;
    height: 2em;
    max-width: 760px !important;
  }
}

.bifold {
  background-image: none;
}
@media all and (min-width: 480px) {
  .bifold {
    background-image: url(/static/images/banner-bifold-doors.png);
    background-repeat: space;
    background-position: center;
  }
}
.bifold .container-row {
  padding: 0;
  display: inline-block;
}
@media all and (min-width: 480px) {
  .bifold .container-row {
    padding: 11em 3%;
    display: block;
  }
}
@media all and (min-width: 1120px) {
  .bifold .container-row {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 6rem;
    margin: auto;
  }
}
@media all and (min-width: 480px) {
  .bifold .container-row .banner-right {
    display: none;
  }
}
@media all and (min-width: 1120px) {
  .bifold .container-row .banner-text {
    line-height: 1;
    max-width: 680px !important;
  }
}

.sash-win {
  background-image: none;
}
@media all and (min-width: 480px) {
  .sash-win {
    background-image: url(/static/images/banner-sash-windows.png);
    background-repeat: space;
    background-position: center;
    padding-top: 23%;
  }
}
.sash-win .container-row {
  padding: 0;
  display: inline-block;
}
@media all and (min-width: 480px) {
  .sash-win .container-row {
    padding: 11em 3%;
    display: block;
  }
}
@media all and (min-width: 1120px) {
  .sash-win .container-row {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 6rem;
    margin: auto;
  }
}
@media all and (min-width: 480px) {
  .sash-win .container-row .banner-right {
    display: none;
  }
}
@media all and (min-width: 1120px) {
  .sash-win .container-row .banner-text {
    line-height: 1;
    max-width: 680px !important;
  }
}

.content-bar {
  background-color: #30cd2f;
  text-align: center;
  width: 100%;
}
.content-bar .container-row {
  max-width: 1400px;
  margin: 0 auto;
  display: inline-block;
  position: relative;
}
@media all and (min-width: 480px) {
  .content-bar .container-row {
    padding: 3em 3%;
  }
}
@media all and (min-width: 1120px) {
  .content-bar .container-row {
    display: block;
  }
}
.content-bar .container-row .family-business {
  position: absolute;
  left: 0;
}
@media all and (min-width: 1120px) {
  .content-bar .container-row .family-business {
    top: -26%;
    left: 1%;
  }
}
@media all and (min-width: 1280px) {
  .content-bar .container-row .family-business {
    left: 1%;
  }
}
@media all and (min-width: 1440px) {
  .content-bar .container-row .family-business {
    left: 4%;
  }
}
.content-bar .container-row .family-business p {
  display: none;
}
@media all and (min-width: 1120px) {
  .content-bar .container-row .family-business p {
    display: inline-block;
    position: absolute;
    color: #000;
    font-size: 1.5em;
    font-weight: 600;
    bottom: -14%;
    left: 0;
    max-width: 100%;
  }
}
.content-bar .container-row .family-business p span {
  text-transform: uppercase;
}
.content-bar .container-row .family-business img {
  display: none;
}
@media all and (min-width: 1120px) {
  .content-bar .container-row .family-business img {
    display: block;
    position: relative; /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  }
}
@media all and (min-width: 1440px) {
  .content-bar .container-row .family-business img {
    left: 0;
  }
}
.content-bar .container-row h1 {
  margin: 0 auto;
  max-width: 97%;
  text-align: center;
  color: #333;
  padding: 0 0 1em 0;
  font-size: 1.4em;
}
@media all and (min-width: 320px) {
  .content-bar .container-row h1 {
    font-size: 1.5em;
  }
}
@media all and (min-width: 480px) {
  .content-bar .container-row h1 {
    font-size: 2em;
  }
}
@media all and (min-width: 1120px) {
  .content-bar .container-row h1 {
    max-width: 70%;
  }
}
.content-bar .container-row p {
  margin: 0 auto;
  text-align: center;
  padding: 0 0 2em 0;
  max-width: 90%;
}
@media all and (min-width: 1120px) {
  .content-bar .container-row p {
    max-width: 55%;
  }
}
.content-bar .container-row p + p {
  padding: 0;
}
.content-bar .container-row ul {
  max-width: 443px;
  width: 100%;
  margin: 0 auto;
  font-family: "Montserrat", sans-serif;
  list-style-position: inside;
}

.supplier-bar {
  background-color: #30662f;
  width: 100%;
}
.supplier-bar .container-row {
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
}
@media all and (min-width: 480px) {
  .supplier-bar .container-row {
    padding: 0 1%;
  }
}
.supplier-bar .container-row h1 {
  text-align: center;
  display: inline-block;
  padding: 0 0 0.5em 0;
  margin: 0;
}
@media all and (min-width: 962px) {
  .supplier-bar .container-row h1 {
    position: absolute;
    left: 50%;
    top: 39%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: "Barlow Semi Condensed";
    font-size: 1.8em;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0.3);
    max-width: 682px;
    width: 100%;
    padding: 0em;
    z-index: 100;
  }
}
.supplier-bar .container-row h2 {
  display: none;
}
@media all and (min-width: 962px) {
  .supplier-bar .container-row h2 {
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: "Barlow Semi Condensed";
    font-size: 1em;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0.675);
    max-width: 530px;
    width: 100%;
    padding: 0.2em 0em;
    z-index: 100;
  }
}
.supplier-bar .container-row .supplier-table {
  display: block;
  text-align: center;
}
@media all and (min-width: 480px) {
  .supplier-bar .container-row .supplier-table {
    display: inline-block;
  }
}
@media all and (min-width: 962px) {
  .supplier-bar .container-row .supplier-table {
    display: table;
    position: relative;
  }
}
.supplier-bar .container-row .supplier-table div {
  display: block;
  margin: 0 auto;
  position: relative;
}
@media all and (min-width: 480px) {
  .supplier-bar .container-row .supplier-table div {
    display: inline-block;
    margin: 0;
  }
}
@media all and (min-width: 962px) {
  .supplier-bar .container-row .supplier-table div {
    display: table-cell;
  }
}
.supplier-bar .container-row .supplier-table div:nth-child(odd) h3 {
  background-color: rgba(0, 0, 0, 0.675);
}
.supplier-bar .container-row .supplier-table div:nth-child(even) h3 {
  background-color: rgba(51, 166, 67, 0.675);
}
.supplier-bar .container-row .supplier-table div img {
  width: 100%;
}
.supplier-bar .container-row .supplier-table div h3 {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 0.3em 0;
}

.single-supp-bar {
  background-color: #339933;
}
.single-supp-bar .container-row {
  display: flex;
  flex-direction: column;
}
@media all and (min-width: 1120px) {
  .single-supp-bar .container-row {
    flex-direction: row;
  }
}
@media all and (min-width: 1120px) {
  .single-supp-bar .container-row .supp-left {
    width: 48%;
    align-self: center;
  }
}
.single-supp-bar .container-row .supp-left img {
  max-width: 100%;
}
@media all and (min-width: 640px) {
  .single-supp-bar .container-row .supp-left img {
    max-width: 50%;
  }
}
.single-supp-bar .container-row .supp-left p {
  text-align: center;
}
@media all and (min-width: 962px) {
  .single-supp-bar .container-row .supp-left p {
    text-align: left;
  }
}
@media all and (min-width: 640px) {
  .single-supp-bar .container-row .supp-right {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media all and (min-width: 1120px) {
  .single-supp-bar .container-row .supp-right {
    width: 48%;
    margin-left: auto;
  }
}
.single-supp-bar .container-row .supp-right .supp-right-container {
  display: flex;
  flex-direction: column;
}
@media all and (min-width: 640px) {
  .single-supp-bar .container-row .supp-right .supp-right-container {
    width: 48%;
    margin-right: 0.5em;
  }
}
.single-supp-bar .container-row .supp-right .supp-right-container .supp-link {
  background-color: #336633;
  display: inline-block;
  padding: 1em;
  margin-bottom: 0.5em;
  text-decoration: none;
}
.single-supp-bar .container-row .supp-right .supp-right-container .supp-link:hover {
  background-color: #448844;
}
@media all and (min-width: 640px) {
  .single-supp-bar .container-row .supp-right .supp-right-container .supp-link {
    flex-basis: 100%;
  }
}
.single-supp-bar .container-row .supp-right .supp-right-container .supp-link span {
  font-weight: 600;
}
.single-supp-bar .container-row .supp-right .supp-right-container .supp-link p {
  margin: 0;
}
.single-supp-bar .container-row .supp-right .supp-right-container img {
  margin: 0 auto 0.5em auto;
  display: block;
}
@media all and (min-width: 640px) {
  .single-supp-bar .container-row .supp-right .supp-right-container img {
    margin: 0;
    width: 100%;
    flex-basis: 100%;
  }
}
.single-supp-bar .container-row .supp-right img {
  margin: 0 auto;
  display: block;
}
@media all and (min-width: 640px) {
  .single-supp-bar .container-row .supp-right img {
    margin: 0;
    width: 45%;
    flex-basis: 45%;
  }
}

.material-bar {
  color: #000;
}
.material-bar .container-row {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 3%;
  position: relative;
}
@media all and (min-width: 640px) {
  .material-bar .container-row {
    padding: 0 1%;
  }
}
.material-bar .container-row h1 {
  text-align: center;
  margin: 0;
  padding: 1em 0;
}
@media all and (min-width: 962px) {
  .material-bar .container-row .material-table {
    display: table;
  }
}
.material-bar .container-row .material-table .single-material {
  margin: 0 auto;
  text-align: center;
}
@media all and (min-width: 640px) {
  .material-bar .container-row .material-table .single-material {
    display: inline-block;
    width: 49%;
  }
}
@media all and (min-width: 962px) {
  .material-bar .container-row .material-table .single-material {
    display: table-cell;
    width: auto;
  }
}
@media all and (min-width: 1280px) {
  .material-bar .container-row .material-table .single-material p {
    display: inline-block;
    width: 75%;
    text-align: center;
  }
}

.gallery-bar {
  width: 100%;
}
.gallery-bar .container-row {
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
}
.gallery-bar .container-row h2 {
  color: #000;
  text-align: center;
}
.gallery-bar .container-row p {
  color: #000;
  text-align: center;
}
.gallery-bar .container-row .image-container {
  flex-direction: column;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}
@media all and (min-width: 640px) {
  .gallery-bar .container-row .image-container {
    flex-direction: row;
    justify-content: center;
  }
}
@media all and (min-width: 962px) {
  .gallery-bar .container-row .image-container {
    flex-wrap: nowrap;
  }
}
.gallery-bar .container-row .image-container .mini-col {
  flex: 0 1 auto;
  align-self: center;
  display: flex;
}
@media all and (min-width: 480px) {
  .gallery-bar .container-row .image-container .mini-col {
    flex: 0 1 auto;
    justify-content: space-between;
  }
}
@media all and (min-width: 640px) {
  .gallery-bar .container-row .image-container .mini-col {
    display: inline;
  }
}
@media all and (min-width: 885px) {
  .gallery-bar .container-row .image-container .mini-col {
    flex: 0 1 14em;
    padding-right: 1em;
  }
}
@media all and (min-width: 962px) {
  .gallery-bar .container-row .image-container .mini-col {
    flex: 1 1 14em;
  }
}
.gallery-bar .container-row .image-container .mini-col a {
  margin: auto;
}
.gallery-bar .container-row .image-container .mini-col a:nth-child(odd) {
  padding-right: 0.5em;
}
@media all and (min-width: 962px) {
  .gallery-bar .container-row .image-container .mini-col a:nth-child(odd) {
    padding-right: 0em;
  }
}
@media all and (min-width: 962px) {
  .gallery-bar .container-row .image-container .mini-col a:nth-child(even) img {
    padding-top: 0.5em;
  }
}
.gallery-bar .container-row .image-container .big-col {
  flex: 0 1 auto;
  display: flex;
  justify-content: center;
}
@media all and (min-width: 962px) {
  .gallery-bar .container-row .image-container .big-col {
    flex: 1 1 auto;
    padding-right: 1em;
  }
}

.content .gallery-wrapper {
  background-color: #30662f;
}
.content .gallery-wrapper .container-row {
  max-width: 1400px;
  margin: 2em auto 0 auto;
  padding: 0 1em;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media all and (min-width: 640px) {
  .content .gallery-wrapper .container-row {
    flex-direction: row;
    padding: 2em 1em;
  }
}
.content .gallery-wrapper .container-row .gallery-text {
  flex: 1 1 auto;
  align-self: center;
  display: inline-block;
  width: 100%;
}
@media all and (min-width: 640px) {
  .content .gallery-wrapper .container-row .gallery-text {
    width: 29%;
    align-self: normal;
    margin-right: 3em;
  }
}
.content .gallery-wrapper .container-row .image-container {
  flex: 1 1 auto;
  align-self: center;
  display: inline-block;
  width: 100%;
}
@media all and (min-width: 640px) {
  .content .gallery-wrapper .container-row .image-container {
    width: 59%;
  }
}
.content .gallery-wrapper .container-row .image-container .main-gallery {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
@media all and (min-width: 640px) {
  .content .gallery-wrapper .container-row .image-container .main-gallery {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}
.content .gallery-wrapper .container-row .image-container .main-gallery .set {
  flex: 1 1 auto;
  position: relative;
  margin-bottom: 1em;
}
@media all and (min-width: 640px) {
  .content .gallery-wrapper .container-row .image-container .main-gallery .set {
    max-width: 45%;
    margin: 0 1em 1em 0;
  }
}
.content .gallery-wrapper .container-row .image-container .main-gallery .set:nth-child(odd) h3 {
  background-color: rgba(0, 0, 0, 0.675);
}
.content .gallery-wrapper .container-row .image-container .main-gallery .set:nth-child(even) h3 {
  background-color: rgba(51, 166, 67, 0.675);
}
.content .gallery-wrapper .container-row .image-container .main-gallery .set a {
  text-decoration: none;
}
.content .gallery-wrapper .container-row .image-container .main-gallery .set a:hover p {
  color: #30cd2f;
}
.content .gallery-wrapper .container-row .image-container .main-gallery .set a img {
  width: 100%;
}
.content .gallery-wrapper .container-row .image-container .main-gallery .set a h3 {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 1em;
  text-align: center;
  padding: 0.3em 0;
}
.content .gallery-wrapper .container-row .image-container .main-gallery .box {
  flex: 1 1 auto;
  position: relative;
  margin-bottom: 1em;
}
@media all and (min-width: 640px) {
  .content .gallery-wrapper .container-row .image-container .main-gallery .box {
    max-width: 45%;
    margin: 0 1em 1em 0;
  }
}
.content .gallery-wrapper .container-row .image-container .main-gallery .box:nth-child(odd) h3 {
  background-color: rgba(0, 0, 0, 0.675);
}
.content .gallery-wrapper .container-row .image-container .main-gallery .box:nth-child(even) h3 {
  background-color: rgba(51, 166, 67, 0.675);
}
.content .gallery-wrapper .container-row .image-container .main-gallery .box a {
  text-decoration: none;
  display: block;
}
.content .gallery-wrapper .container-row .image-container .main-gallery .box a:hover p {
  color: #30cd2f;
}
.content .gallery-wrapper .container-row .image-container .main-gallery .box a img {
  width: 100%;
}
.content .gallery-wrapper .container-row .image-container .main-gallery .box a h3 {
  position: absolute;
  bottom: 31px;
  width: 100%;
  font-size: 1em;
  text-align: center;
  padding: 0.3em 0;
}

.contact-content {
  margin-top: 2em;
}
.contact-content .container-row {
  margin: 2em auto 0 auto;
  display: block;
}
.contact-content .container-row h1 {
  padding: 0;
}

.contact-container .container-row {
  display: block;
  font-family: "Montserrat", sans-serif;
}
.contact-container .container-row h2 {
  margin-bottom: 0.5em;
}
.contact-container .container-row p {
  margin: 0;
}
@media all and (min-width: 885px) {
  .contact-container .container-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.contact-container .container-row .contact-left {
  color: #30662f;
  text-align: center;
}
@media all and (min-width: 885px) {
  .contact-container .container-row .contact-left {
    width: 20%;
  }
}
.contact-container .container-row .contact-left .email-contact a {
  color: #30662f;
  margin-top: 1em;
}
.contact-container .container-row .contact-left .tele-contact a {
  color: #30662f;
}
.contact-container .container-row .contact-center {
  color: #30662f;
  text-align: left;
  position: relative;
}
@media all and (min-width: 885px) {
  .contact-container .container-row .contact-center {
    width: 34%;
  }
}
.contact-container .container-row .contact-center h2 {
  text-align: center;
}
.contact-container .container-row .contact-center .field {
  max-width: 338px;
  margin: 0.5em auto;
}
@media all and (min-width: 885px) {
  .contact-container .container-row .contact-center .field {
    width: 100%;
    max-width: 33em;
    margin: 0 auto 0.5em auto;
  }
}
.contact-container .container-row .contact-center .check-field {
  width: 100%;
  display: block;
  text-align: left;
}
.contact-container .container-row .contact-center .text-input {
  width: 100%;
  display: block;
  max-width: 324px;
}
@media all and (min-width: 885px) {
  .contact-container .container-row .contact-center .text-input {
    max-width: 35.5em;
  }
}
.contact-container .container-row .contact-center label {
  max-width: 338px;
  margin: 0 auto;
  display: block;
}
.contact-container .container-row .contact-center label span {
  color: red;
}
@media all and (min-width: 885px) {
  .contact-container .container-row .contact-center label {
    max-width: 33em;
  }
}
.contact-container .container-row .contact-center .-small label {
  width: 7em;
  display: inline-block;
}
.contact-container .container-row .contact-center .-small .text-input {
  display: inline-block;
  width: 62%;
  float: right;
}
.contact-container .container-row .contact-center .submit button {
  color: #30cd2f;
  background: 0;
  background-image: url("/static/images/send.png");
  background-repeat: no-repeat;
  background-position: right;
  border: none;
  width: 5em;
  text-align: left;
  font-size: 1em;
  padding: 0;
}
.contact-container .container-row .contact-center .submit button:hover {
  cursor: pointer;
}
.contact-container .container-row .contact-right {
  color: #30662f;
  text-align: center;
}
@media all and (min-width: 885px) {
  .contact-container .container-row .contact-right {
    width: 20%;
  }
}
.contact-container .container-row .contact-right .find-contact .map-container {
  max-width: 338px;
  max-height: 228px;
  height: 228px;
  width: 100%;
  margin: 0 auto;
}
.requiredfield {
  display: inline-block;
  margin-bottom: 0.5em;
}
.requiredfield span {
  color: red;
}

.message {
  display: inline-block;
}

.footer {
  background-color: #2a2a2a;
  width: 100%;
}
.footer .container-row {
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
}
.footer .container-row .footer-table {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
@media all and (min-width: 640px) {
  .footer .container-row .footer-table {
    flex-direction: row;
  }
}
@media all and (min-width: 1120px) {
  .footer .container-row .footer-table {
    flex-wrap: nowrap;
    justify-content: center;
  }
}
.footer .container-row .footer-table .latest-installation {
  position: relative;
  flex: 1 1 auto;
  width: 100%;
}
@media all and (min-width: 480px) {
  .footer .container-row .footer-table .latest-installation {
    padding-bottom: 2em;
  }
}
@media all and (min-width: 1120px) {
  .footer .container-row .footer-table .latest-installation {
    flex: 1 1 auto;
    width: auto;
    padding-left: 13em;
  }
}
.footer .container-row .footer-table .latest-installation .latest-text {
  background-color: rgba(48, 205, 47, 0.6);
  padding: 1em;
}
@media all and (min-width: 480px) {
  .footer .container-row .footer-table .latest-installation .latest-text {
    position: absolute;
    max-width: 229px;
    z-index: 100;
    top: 50px;
    padding-bottom: 3em;
  }
}
@media all and (min-width: 1120px) {
  .footer .container-row .footer-table .latest-installation .latest-text {
    left: -20px;
  }
}
@media all and (min-width: 1600px) {
  .footer .container-row .footer-table .latest-installation .latest-text {
    left: 0px;
  }
}
.footer .container-row .footer-table .latest-installation h4 {
  margin: 0;
  text-transform: uppercase;
  padding-bottom: 0.5em;
  font-size: 1.2em;
}
.footer .container-row .footer-table .latest-installation p {
  margin: 0;
  font-size: 1.2em;
}
.footer .container-row .footer-table .latest-installation img {
  border: 5px solid #fff;
  width: 96%;
  margin-top: 0.5em;
}
@media all and (min-width: 480px) {
  .footer .container-row .footer-table .latest-installation img {
    max-width: 326px;
    transform: rotate(-7deg);
    display: inline-block;
    float: right;
    margin: 1em 1em 0 0;
  }
}
@media all and (min-width: 640px) {
  .footer .container-row .footer-table .latest-installation img {
    margin-left: 15em;
    float: none;
  }
}
@media all and (min-width: 1120px) {
  .footer .container-row .footer-table .latest-installation img {
    margin-left: 0;
  }
}
.footer .container-row .footer-table .latest-installation:after {
  content: "";
  display: block;
  clear: both;
}
.footer .container-row .footer-table .footer-products {
  flex: 1 1 auto;
  padding-top: 1em;
}
.footer .container-row .footer-table .footer-products h4 {
  color: #33a643;
  margin: 0;
  text-transform: uppercase;
}
.footer .container-row .footer-table .footer-products ul {
  padding: 0;
  margin: 0;
}
.footer .container-row .footer-table .footer-products ul li {
  list-style-type: none;
  padding-top: 0.3em;
}
.footer .container-row .footer-table .footer-products ul li:last-child {
  padding-bottom: 0.5em;
}
.footer .container-row .footer-table .footer-products ul li a {
  text-decoration: none;
}
.footer .container-row .footer-table .footer-products .showroom-footer {
  max-width: 178px;
  background-color: rgba(48, 205, 47, 0.6);
  border: 3px solid white;
  padding: 0.5em;
  width: 56%;
}
@media all and (min-width: 1120px) {
  .footer .container-row .footer-table .footer-products .showroom-footer {
    width: 60%;
  }
}
@media all and (min-width: 1440px) {
  .footer .container-row .footer-table .footer-products .showroom-footer {
    width: 50%;
  }
}
.footer .container-row .footer-table .footer-products .showroom-footer h4 {
  font-size: 1.2em;
  color: #fff;
}
.footer .container-row .footer-table .footer-products .showroom-footer p {
  margin: 0;
  font-weight: 500;
}
.footer .container-row .footer-table .footer-products .showroom-footer a {
  text-decoration: none;
}
.footer .container-row .footer-table .footer-contact {
  flex: 1 1 auto;
  padding-top: 1em;
}
.footer .container-row .footer-table .footer-contact h4 {
  color: #33a643;
  margin: 0;
  text-transform: uppercase;
}
.footer .container-row .footer-table .footer-contact ul {
  padding: 0;
  margin: 0;
}
.footer .container-row .footer-table .footer-contact ul li {
  list-style-type: none;
  padding-top: 0.3em;
}
.footer .container-row .footer-table .footer-contact ul li a {
  text-decoration: none;
}
.footer .container-row .footer-table .footer-logos {
  flex: 1 1 auto;
  padding-top: 1em;
}
@media all and (min-width: 1120px) {
  .footer .container-row .footer-table .footer-logos {
    flex: 1 1 2em;
  }
}
.footer .container-row .footer-table .footer-logos img {
  margin-bottom: 1em;
}
.footer .container-row .traiding {
  margin: 0;
  text-align: left;
  width: 100%;
}
@media all and (min-width: 640px) {
  .footer .container-row .traiding {
    text-align: right;
  }
}
@media all and (min-width: 1440px) {
  .footer .container-row .traiding {
    width: 97%;
  }
}

.container-row {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2em 3%;
  position: relative;
  display: inline-block;
}
@media all and (min-width: 1120px) {
  .container-row {
    display: block;
  }
}

.general ul {
  max-width: 55%;
  margin: 0 auto;
  font-family: "Montserrat", sans-serif;
}
.general h3 {
  max-width: 55%;
  margin: 1em auto;
  text-align: center;
}
.general p {
  padding: 0 0 1em 0;
}

.reviews iframe {
  min-height: 965px;
}

@media screen and (min-width: 768px) {
  iframe {
    min-height: 535px;
  }
}