.header{
	width: 100%;
	background-color: #fff;
    margin-top: 0.5em;

	.container-row{
		position: relative;
		max-width: 1400px;
		margin: 0 auto;
		padding: 0 3%;
        display: block;

        @include media-query(normal){
            padding: 0 1%;
        }

		.heading{

            @include media-query(normal){
                float: left;
            }

			h1{
				margin: 0;

				a{

					img{
						margin: 0 auto;
						display: block;

						@include media-query(micro){
							margin: 0;
						}
					}
				}
			}
		}

		.contact-info{
			display: none;

			@include media-query(nano){
				margin: 0 auto;
				display: table;
			}

			@include media-query(micro){
				margin: 0;
				display: block;
                width: 100%;
			}

            @include media-query(normal){
                float:left;
                margin-top: 4.2em;
                width: auto;
            }

            @include media-query(mega){
                margin-left: 2em;
            }

            @include media-query(giga){
                margin-left: 5em;
            }

            @include media-query(tera){
                margin-left: 5em;
            }

			a{
				color: #000;
				display: block;
				text-align: center;
				padding: 0.5em 0;
				text-decoration: none;
				width: 100%;

				&:hover{
					font-weight: 600;

				}

				@include media-query(nano){
					display: table-cell;
					vertical-align: middle;
					width: 33%;
				}

				@include media-query(micro){
					display: inline-block;
					margin-right: 2em;
					width: auto;
				}

                @include media-query(kilo){
                    margin-right: 2em;
                }

				@include media-query(tera){
					margin-right: 5em;
				}
			}
		}

		.header-logos{
			text-align: center;
			margin-top: 1em;

			@include media-query(nano){
				margin: 0;
				padding-bottom: 0.5em;
			}

            @include media-query(micro){
                position: absolute;
                top:50px;
                left: 350px;
            }

            @include media-query(normal){
                top: 57px;
                left: 766px;
            }

            @include media-query(kilo) {
                left: 862px;
            }

            @include media-query(mega){
                left: 900px;
            }

            @include media-query(giga){
                top: 49px;
                left: 906px;
            }

            @include media-query(tera){
                top: 45px;
                left: 983px;
            }

			a{
				img{
				display: inline-block;
				vertical-align: middle;
				}

                .which-header {
                    max-width: 20%;

                    @include media-query(micro) {
                        max-width: 29%;
                    }
                }

			@include media-query(giga){
				padding-right: 2em;
			}

			@include media-query(tera){
				padding-right: 3em;
			}
			}
		}

        .showroom-box{
            display: none;

            @include media-query(kilo){
                width: 140px;
                border: 1px solid #000;
                display: block;
                position: absolute;
                left: 1030px;
                top: 36px;
                text-align: center;
            }

            @include media-query(mega){
                left: 1100px;
            }

            @include media-query(giga){
                left: 1200px;
            }

            @include media-query(tera){
                left: 1270px;
                top: 43px;
            }

            p{
               color: #000;
               margin: 0;
               padding-top: 0.5em;

               @include media-query(tera){
               	   padding-top: 0.2em;
               }
			}

	        .showroom-times{
	        	font-size: 0.9em;
                padding: 0.2em 1em 0 1em;
	        }

	        .showroom-visit{
	        	color: $logo-green;
                font-family: $body-font;
                font-weight: 500;

                .showroom-york{
                    font-weight: 900;
                    font-family: $body-font;
                }
	        }
        }

		.mobile-nav{
			background-image: url(/static/images/mob-menu.png);
			background-position: right 50%;
			background-repeat: no-repeat;
			background-size: 50px;
			border-top: 2px solid $logo-green;
			font-size: 1.2rem;
			width: 100%;
			height: 50px;
			margin-top:1em;

			@include media-query(nano){
				margin: 0;
			}

			@include media-query(micro){
				display: none;
				height: 0;
			}
		}

		.nav{

            @include media-query(milli){
                float: left;
            }

            @include media-query(kilo){
                width: 70%;
            }

            @include media-query(mega){
                margin-left: 2em;
            }

            @include media-query(giga){
                margin-left: 5em;
            }

            @include media-query(tera){
                margin-left: 5em;
            }
            .nav-wrap {
                position: relative;
                z-index: 100;

    			ul{
    				display: block;
    				list-style-type: none;
    				text-align: center;
    				margin: 0;
    				padding: 0;

                    li {
                        list-style: none;
                    }

    				@include media-query(micro){
    					display: table;
    					border-top: 1px solid #000;
    					border-bottom: 1px solid #000;
    				}

                    .has-children {
                        .dropdown {
                            display: none;
                            position: relative;
                            background-color: #fff;

                            @include media-query(micro) {
                                max-width: 190px;
                                position: absolute;
                            }

                                ul {

                                    @include media-query(micro) {
                                        display: inline-block;
                                        border: 1px solid #000;
                                        border-top: none;
                                    }

                                    li {
                                        display: inline-block;
                                        width: 100%;
                                        background-color: #fff;
                                        list-style: none;

                                        @include media-query(micro) {
                                            float: none;
                                            margin: 0;
                                            padding: 0;
                                            text-align: left;
                                            padding: 0.25em 0.5em;
                                            width: 90%;
                                        }

                                         &:hover {
                                            background-color: $logo-green;

                                            @include media-query(micro) {
                                                background-color: #fff;
                                            }

                                            .dropdown {
                                                background-color: $logo-green;
                                            }

                                            a {
                                                color: #fff;

                                                @include media-query(micro) {
                                                    color: #000;
                                                    font-weight: 600;
                                                }
                                            }
                                        }
                                        a {
                                            color: #000;
                                        }
                                    }
                                }
                        }

                        &:hover {
                            .dropdown {
                                    display: block;
                                }
                            }
                        }

    				li{
                        background-color: #fff;
    					display: block;
    					list-style-type: none;
    					padding: 0.25em 0;
    					width: 100%;

    					&:last-child {
    						@include media-query(micro){
    							padding-right: 0;
    						}
    					}

    					@include media-query(micro){
                            background-color: transparent;
    						float: left;
    						padding-right: 1em;
    						display: table-cell;
    						width: auto;
    					}

                        @include media-query(milli){
                            padding-right: 1em;
                        }

                        @include media-query(normal){
                            padding-right: 1em;
                        }

                        @include media-query(kilo){
                            padding-right: 2em;
                        }

                        @include media-query(tera){
                        	padding-right: 3em;
                        }

    					a{
    						color: #000;
    						display: block;
    						width: 100%;
    						text-decoration: none;

    						@include media-query(micro){
    							width: auto;
    						}

    					}

    					&:hover{
    						background-color: $logo-green;
    						color: #fff;

    						a{
    							color: #fff;

    							@include media-query(micro){
    								color: #000;
                                    font-weight: 600;
    							}
    						}

    						@include media-query(micro){
    							background-color: transparent;
    							color: #000;
    						}
    					}
    				}

    				.active{
    					background-color: $logo-green;

    					a{
    						color: #fff;

    						@include media-query(micro){
    							color: #000;
    						}
    					}

    					@include media-query(micro){
    						background-color: transparent;
    						font-weight: 600;
    					}
    				}
    			}
    		}
        }

		.menu-text{
			color: #000;
			font-size: 1.2em;
			text-align: center;
			padding: 0.4em 0;
			margin: 0;
			text-transform: uppercase;
			cursor: pointer;

			@include media-query(micro){
				display: none;
			}
		}
	}
	&:after{
		content: '';
		display: block;
		clear: both;
	}
}

.-closed {
	display: none;
}
