.footer{
    background-color: #2a2a2a;
    width: 100%;

    .container-row{
        max-width: 1400px;
        margin: 0 auto;
        position: relative;

        .footer-table{
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;

                @include media-query(micro){
                    flex-direction: row;
                }

                @include media-query(kilo){
                    flex-wrap: nowrap;
                    justify-content: center;
                }

            .latest-installation{
                position: relative;
                flex: 1 1 auto;
                width: 100%;

                @include media-query(nano){
                    padding-bottom: 2em;
                }

                @include media-query(kilo){
                    flex: 1 1 auto;
                    width: auto;
                    padding-left: 13em;
                }

                .latest-text{
                    background-color: rgba(48,205,47, 0.6);
                    padding: 1em;

                    @include media-query(nano){
                        position: absolute;
                        max-width: 229px;
                        z-index: 100;
                        top: 50px;
                        padding-bottom: 3em;
                    }

                    @include media-query(kilo){
                        left: -20px;
                    }

                    @include media-query(tera){
                        left: 0px;
                    }
                }

                h4{
                    margin: 0;
                    text-transform: uppercase;
                    padding-bottom: 0.5em;
                    font-size: 1.2em;
                }

                p{
                    margin: 0;
                    font-size: 1.2em;
                }

                img{
                    border: 5px solid #fff;
                    width: 96%;
                    margin-top: 0.5em;

                    @include media-query(nano){
                        max-width: 326px;
                        transform: rotate(-7deg);
                        display: inline-block;
                        float: right;
                        margin: 1em 1em 0 0;
                    }

                    @include media-query(micro){
                        margin-left: 15em;
                        float: none;
                    }

                    @include media-query(kilo){
                        margin-left: 0;
                    }
                }

                &:after{
                    content: "";
                    display: block;
                    clear: both;
                }
            }
            .footer-products{
                flex: 1 1 auto;
                padding-top: 1em;

                h4{
                    color: $logo-green;
                    margin: 0;
                    text-transform: uppercase;
                }

                ul{
                    padding: 0;
                    margin: 0;

                    li{
                        list-style-type: none;
                        padding-top: 0.3em;

                        &:last-child{
                            padding-bottom: 0.5em;
                        }

                        a{
                            text-decoration: none;
                        }
                    }
                }

                .showroom-footer{
                    max-width: 178px;
                    background-color: rgba(48,205,47, 0.6);
                    border: 3px solid white;
                    padding: 0.5em;
                    width: 56%;

                    @include media-query(kilo){
                        width: 60%;
                    }

                    @include media-query(giga){
                        width: 50%;
                    }

                    h4{
                        font-size: 1.2em;
                        color: #fff;
                    }

                    p{
                        margin: 0;
                        font-weight: 500;
                    }

                    a{
                        text-decoration: none;
                    }
                }
            }
            .footer-contact{
                flex: 1 1 auto;
                padding-top: 1em;

                h4{
                    color: $logo-green;
                    margin: 0;
                    text-transform: uppercase;
                }

                ul{
                    padding: 0;
                    margin: 0;

                    li{
                        list-style-type: none;
                        padding-top: 0.3em;

                        a{
                            text-decoration: none;
                        }
                    }
                }
            }
            .footer-logos{
                flex: 1 1 auto;
                padding-top: 1em;

                @include media-query(kilo){
                    flex: 1 1 2em;
                }

                img{
                    margin-bottom: 1em;
                }

            }
        }
        .traiding{
            margin: 0;
            text-align: left;
            width: 100%;

            @include media-query(micro) {
                text-align: right;
            }

            @include media-query(giga) {
                width: 97%;
            }
        }
    }
}
