.container-row{
    max-width: 1400px;
    margin: 0 auto;
    padding: 2em 3%;
    position: relative;
    display: inline-block;

    @include media-query(kilo) {
        display: block;
    }
}

.general {
    ul {
        max-width: 55%;
        margin: 0 auto;
        font-family: $body_font;
    }
    h3 {
        max-width: 55%;
        margin: 1em auto;
        text-align: center;
    }

    p {
        padding: 0 0 1em 0;
    }
}

.reviews iframe

{ min-height: 965px }
@media screen and (min-width: 768px) { iframe

{ min-height: 535px }
}