.contact-content {
    margin-top: 2em;

    .container-row {
        margin: 2em auto 0 auto;
        display: block;

        h1 {
            padding: 0;
        }
    }
}
.contact-container {

    .container-row {
        display: block;
        font-family: $body_font;

        h2 {
            margin-bottom: 0.5em;
        }

        p {
            margin: 0;
        }

        @include media-query(milli) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .contact-left {
            color: $dark-green;
            text-align: center;

            @include media-query(milli) {
                width: 20%;
            }

            .email-contact {
                a {
                    color: $dark-green;
                    margin-top: 1em;
                }
            }

            .tele-contact {
                a {
                    color: $dark-green;
                }
            }

            .visit-contact {

            }

            .opening-contact {

            }

            .which-contact {

            }
        }

        .contact-center {
            color: $dark-green;
            text-align: left;
            position: relative;

            @include media-query(milli) {
                width: 34%;
            }

            h2 {
                text-align: center;
            }

            .field {
                max-width: 338px;
                margin: 0.5em auto;

                @include media-query(milli) {
                    width: 100%;
                    max-width: 33em;
                    margin: 0 auto 0.5em auto;
                }
            }
            .check-field {
                width: 100%;
                display: block;
                text-align: left;
            }
            .text-input {
                width: 100%;
                display: block;
                max-width: 324px;

                @include media-query(milli) {
                    max-width: 35.5em;
                }
            }

            label {
                max-width: 338px;
                margin: 0 auto;
                display: block;

                span {
                    color: red;
                }

                @include media-query(milli) {
                    max-width: 33em;
                }
            }

            .-small {
                label {
                    width: 7em;
                    display: inline-block;
                }
                .text-input {
                    display: inline-block;
                    width: 62%;
                    float: right;
                }
            }
            .submit {
                button {
                    color: $light-green;
                    background: 0;
                    background-image: url("/static/images/send.png");
                    background-repeat: no-repeat;
                    background-position: right;
                    border: none;
                    width: 5em;
                    text-align: left;
                    font-size: 1em;
                    padding: 0;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

        .contact-right {
            color: $dark-green;
            text-align: center;

            @include media-query(milli) {
                width: 20%;
            }

            .find-contact {

                .map-container {
                    max-width: 338px;
                    max-height: 228px;
                    height: 228px;
                    width: 100%;
                    margin: 0 auto;
                }
            }

            .showroom-contact {

            }
        }
    }
}
.requiredfield {
    display: inline-block;
    // float: right;
    margin-bottom: 0.5em;

    span {
        color: red;
    }
}
.message {
    display: inline-block;
}
