//
// Palette
//

$text-color: #fff;

$heading-color: #fff;
$heading-sec: #333;

$light-green: #30cd2f;
$dark-green: #30662f;
$logo-green: #33a643;
