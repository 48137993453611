.supplier-bar{
    background-color: $dark-green;
    width: 100%;

    .container-row{
        max-width: 1400px;
        margin: 0 auto;
        position: relative;

        @include media-query(nano){
            padding: 0 1%;
        }

        h1{
            text-align: center;
            display: inline-block;
            padding: 0 0 0.5em 0;
            margin: 0;

            @include media-query(normal){
                position: absolute;
                left: 50%;
                top: 39%;
                transform: translate(-50%, -50%);
                text-align: center;
                font-family: $sec-body;
                font-size: 1.8em;
                text-transform: uppercase;
                background-color: rgba(0,0,0,0.3);
                max-width: 682px;
                width: 100%;
                padding: 0em;
                z-index: 100;
            }
        }

        h2{
            display: none;

            @include media-query(normal){
                display: inline-block;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                font-family: $sec-body;
                font-size: 1em;
                text-transform: uppercase;
                background-color: rgba(0,0,0,0.675);
                max-width: 530px;
                width: 100%;
                padding: 0.2em 0em;
                z-index: 100;
            }
        }

        .supplier-table{
            display: block;
            text-align: center;

            @include media-query(nano){
                display: inline-block;
            }

            @include media-query(normal){
                display: table;
                position: relative;
            }

            div{
                display: block;
                margin: 0 auto;
                position: relative;

                @include media-query(nano){
                    display: inline-block;
                    margin: 0;
                }

                @include media-query(normal){
                    display: table-cell;
                }

                &:nth-child(odd){
                    h3{
                        background-color: rgba(0, 0, 0, 0.675);
                    }
                }

                &:nth-child(even){
                    h3{
                        background-color: rgba(51,166,67, 0.675);
                    }
                }

                img{
                    width: 100%;
                }

                h3{
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    text-align: center;
                    padding: 0.3em 0;

                }
            }
        }
    }
}
