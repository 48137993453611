.material-bar{
    color: #000;

    .container-row{
        max-width: 1400px;
        margin: 0 auto;
        padding: 0 3%;
        position: relative;

        @include media-query(micro){
            padding: 0 1%;
        }

        h1{
            text-align: center;
            margin: 0;
            padding: 1em 0;
        }

        .material-table{

            @include media-query(normal){
                display: table;
            }

            .single-material{
                margin: 0 auto;
                text-align: center;

                @include media-query(micro){
                    display: inline-block;
                    width: 49%;
                }

                @include media-query(normal){
                    display: table-cell;
                    width: auto;
                }

                p{
                    @include media-query(mega){
                        display: inline-block;
                        width: 75%;
                        text-align: center;
                    }
                }
            }
        }
    }
}
