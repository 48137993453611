.inner-banner{
    margin-top: 0;

    .container-row{
            max-width: 1400px;
            margin: 0 auto;
            padding: 0;
            position: relative;
            display: inline-block;

            @include media-query(kilo){
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                height: 3rem;
                margin: auto;
            }
    }
    .banner-text {
        line-height: 1;
        height: 1em;
        max-width: 740px !important;
    }
}

.inner-crumbtrail{
    background-color: #ccffcc;
    margin-top: 2em;

        .container-row{
            max-width: 1400px;
            margin: 0 auto;
            padding: 0;

            .breadcrumb{
                color: #336633;
                margin: 0;
                padding: 0.5em 0em 0.5em 1em;
                font-size: 0.8em;
                font-weight: 600;

                @include media-query(giga){
                    padding: 0.5em 0em;
                }

                a{
                    color: #336633;
                    text-decoration: none;
                }
            }
        }
}
.alum-wind {
    background-image: none;

    @include media-query(nano) {
        background-image: url(/static/images/banner-alum-doors.png);
        background-repeat: space; //May be a slight hack but will only notice on 3600px and above.
        background-position: center;
    }
    .container-row {
        padding: 0;
        display: inline-block;

        @include media-query(nano) {
        padding: 11em 3%;
        display: block;
        }
        @include media-query(kilo){
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            height: 6rem;
            margin: auto;
        }

        .banner-right {

            @include media-query(nano) {
                display: none;
            }
        }
        .banner-text {
            @include media-query(kilo) {
                max-width: 760px !important;
            }
        }
    }
}

.pvcu {
    background-image: none;

    @include media-query(nano) {
        background-image: url(/static/images/banner-pvcu-windows.png);
        background-repeat: space; //May be a slight hack but will only notice on 3600px and above.
        background-position: center;
    }

    .container-row {
        padding: 0;
        display: inline-block;

        @include media-query(nano) {
        padding: 11em 3%;
        display: block;
        }
        @include media-query(kilo){
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            height: 6rem;
            margin: auto;
        }

        .banner-right {

            @include media-query(nano) {
                display: none;
            }
        }
    }
}

.trad-pvcu {
    background-image: none;

    @include media-query(nano) {
        background-image: url(/static/images/banner-traditional-pvcs.png);
        background-repeat: space; //May be a slight hack but will only notice on 3600px and above.
        background-position: center;
    }

    .container-row {
        padding: 0;
        display: inline-block;

        @include media-query(nano) {
        padding: 11em 3%;
        display: block;
        }
        @include media-query(kilo){
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            height: 6rem;
            margin: auto;
        }

        .banner-right {

            @include media-query(nano) {
                display: none;
            }
        }
    }
}

.alum-door {
    background-image: none;

    @include media-query(nano) {
        background-image: url(/static/images/banner-alum-doors.png);
        background-repeat: space; //May be a slight hack but will only notice on 3600px and above.
        background-position: center;
    }

    .container-row {
        padding: 0;
        display: inline-block;

        @include media-query(nano) {
        padding: 11em 3%;
        display: block;
        }
        @include media-query(kilo){
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            height: 6rem;
            margin: auto;
        }

        .banner-right {

            @include media-query(nano) {
                display: none;
            }
        }
    }
}

.timb-wind {
    background-image: none;

    @include media-query(nano) {
        background-image: url(/static/images/banner-timb-wind.png);
        background-repeat: space; //May be a slight hack but will only notice on 3600px and above.
        background-position: center;
        padding-top: 22%;
    }

    .container-row {
        padding: 0;
        display: inline-block;

        @include media-query(nano) {
        padding: 11em 3%;
        display: block;
        }
        @include media-query(kilo){
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            height: 6rem;
            margin: auto;
        }

        .banner-right {

            @include media-query(nano) {
                display: none;
            }
        }
    }
}

.timb-door {
    background-image: none;

    @include media-query(nano) {
        background-image: url(/static/images/banner-timber-doors.png);
        background-repeat: space; //May be a slight hack but will only notice on 3600px and above.
        background-position: center;
    }

    .container-row {
        padding: 0;
        display: inline-block;

        @include media-query(nano) {
        padding: 11em 3%;
        display: block;
        }
        @include media-query(kilo){
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            height: 6rem;
            margin: auto;
        }

        .banner-right {

            @include media-query(nano) {
                display: none;
            }
        }
    }
}

.door-main {
    background-image: none;

    @include media-query(nano) {
        background-image: url(/static/images/banner-wind-main.png);
        background-repeat: space; //May be a slight hack but will only notice on 3600px and above.
        background-position: center;
    }

    .container-row {
        padding: 0;
        display: inline-block;

        @include media-query(nano) {
        padding: 11em 3%;
        display: block;
        }
        @include media-query(kilo){
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            height: 6rem;
            margin: auto;
        }

        .banner-right {

            @include media-query(nano) {
                display: none;
            }
        }
        .banner-text {
            @include media-query(kilo) {
                height: 2em;
                max-width: 760px !important;
            }
        }
    }
}

.comp-doors {
    background-image: none;

    @include media-query(nano) {
        background-image: url(/static/images/banner-composite-doors.png);
        background-repeat: space; //May be a slight hack but will only notice on 3600px and above.
        background-position: center;
    }

    .container-row {
        padding: 0;
        display: inline-block;

        @include media-query(nano) {
        padding: 11em 3%;
        display: block;
        }

        @include media-query(kilo){
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            height: 6rem;
            margin: auto;
        }


        .banner-right {

            @include media-query(nano) {
                display: none;
            }
        }
    }
}

.roof-banner {
    background-image: none;

    @include media-query(nano) {
        background-image: url(/static/images/banner-roofline.png);
        background-repeat: space; //May be a slight hack but will only notice on 3600px and above.
        background-position: center;
    }

    .container-row {
        padding: 0;
        display: inline-block;

        @include media-query(nano) {
        padding: 11em 3%;
        display: block;
        }
        @include media-query(kilo){
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            height: 3rem;
            margin: auto;
        }

        .banner-right {

            @include media-query(nano) {
                display: none;
            }
        }
    }
}

.conser-banner {
    background-image: none;

    @include media-query(nano) {
        background-image: url(/static/images/banner-conser.png);
        background-repeat: space; //May be a slight hack but will only notice on 3600px and above.
        background-position: center;
    }

    .container-row {
        padding: 0;
        display: inline-block;

        @include media-query(nano) {
        padding: 11em 3%;
        display: block;
        }
        @include media-query(kilo){
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            height: 3rem;
            margin: auto;
        }

        .banner-right {

            @include media-query(nano) {
                display: none;
            }
        }
        .banner-text {
            @include media-query(kilo) {
                max-width: 790px !important;
            }
        }
    }
}

.pvcu-door {
    background-image: none;

    @include media-query(nano) {
        background-image: url(/static/images/banner-pvcu-doors.png);
        background-repeat: space; //May be a slight hack but will only notice on 3600px and above.
        background-position: center;
    }

    .container-row {
        padding: 0;
        display: inline-block;

        @include media-query(nano) {
        padding: 11em 3%;
        display: block;
        }
        @include media-query(kilo){
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            height: 6rem;
            margin: auto;
        }


        .banner-right {

            @include media-query(nano) {
                display: none;
            }
        }
    }
}

.wind-main {
    background-image: none;

    @include media-query(nano) {
        background-image: url(/static/images/banner-doors-main.png);
        background-repeat: space; //May be a slight hack but will only notice on 3600px and above.
        background-position: center;
    }

    .container-row {
        padding: 0;
        display: inline-block;

        @include media-query(nano) {
        padding: 11em 3%;
        display: block;
        }
        @include media-query(kilo){
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            height: 6rem;
            margin: auto;
        }

        .banner-right {

            @include media-query(nano) {
                display: none;
            }
        }
        .banner-text {
            @include media-query(kilo) {
                line-height: 1;
                height: 2em;
                max-width: 760px !important;
            }
        }
    }
}

.bifold {
    background-image: none;

    @include media-query(nano) {
        background-image: url(/static/images/banner-bifold-doors.png);
        background-repeat: space; //May be a slight hack but will only notice on 3600px and above.
        background-position: center;
    }

    .container-row {
        padding: 0;
        display: inline-block;

        @include media-query(nano) {
        padding: 11em 3%;
        display: block;
        }
        @include media-query(kilo){
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            height: 6rem;
            margin: auto;
        }

        .banner-right {

            @include media-query(nano) {
                display: none;
            }
        }
        .banner-text {
            @include media-query(kilo) {
                line-height: 1;
                max-width: 680px !important;
            }
        }
    }
}

.sash-win {
    background-image: none;

    @include media-query(nano) {
        background-image: url(/static/images/banner-sash-windows.png);
        background-repeat: space; //May be a slight hack but will only notice on 3600px and above.
        background-position: center;
        padding-top: 23%;
    }

    .container-row {
        padding: 0;
        display: inline-block;

        @include media-query(nano) {
        padding: 11em 3%;
        display: block;
        }
        @include media-query(kilo){
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            height: 6rem;
            margin: auto;
        }

        .banner-right {

            @include media-query(nano) {
                display: none;
            }
        }
        .banner-text {
            @include media-query(kilo) {
                line-height: 1;
                max-width: 680px !important;
            }
        }
    }
}
