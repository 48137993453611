.landing-banner{
    .container-row{
            max-width: 1400px;
            margin: 0 auto;
            padding: 0;
            position: relative;
            display: inline-block;

            @include media-query(kilo){
                padding: 13em 3%;
                display: flex !important;
            };
    }
    .banner-text {
        @include media-query(kilo) {
            max-width: 740px !important;
        }
    }
}
