.content-bar{
    background-color: $light-green;
    text-align:center;
    width: 100%;

    .container-row{
        max-width: 1400px;
        margin: 0 auto;
        display: inline-block;
        position: relative;

        @include media-query(nano) {
            padding: 3em 3%;
        }

        @include media-query(kilo){
            display: block;
        }

        .family-business{
            position: absolute;
            left: 0;

            @include media-query(kilo){
                top: -26%;
                left: 1%;
            }

            @include media-query(mega){
                left: 1%;
            }

            @include media-query(giga) {
                left: 4%;
            }

            p{
                display: none;

                @include media-query(kilo){
                    display: inline-block;
                    position: absolute;
                    color: #000;
                    font-size: 1.5em;
                    font-weight: 600;
                    bottom: -14%;
                    left: 0;
                    max-width: 100%;
                }

                span{
                    text-transform: uppercase;
                }
            }

            img{
                display: none;

                @include media-query(kilo){
                    display: block;
                    position: relative; /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
                }

                @include media-query(giga){
                    left: 0;
                }
            }
        }

        h1{
            margin: 0 auto;
            max-width: 97%;
            text-align: center;
            color: #333;
            padding: 0 0 1em 0;
            font-size: 1.4em;

            @include media-query(pico){
                font-size: 1.5em;
            }

            @include media-query(nano){
                font-size: 2em;
            }

            @include media-query(kilo){
                max-width: 70%;
            }
        }

        p{
            margin: 0 auto;
            text-align: center;
            padding:0 0 2em 0;
            max-width: 90%;

            @include media-query(kilo){
                max-width: 55%;
            }

            + p{
                padding: 0;
            }
        }
    ul {
        max-width: 443px;
        width: 100%;
        margin: 0 auto;
        font-family: $body_font;
        list-style-position: inside;
    }
    }
}