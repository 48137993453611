.content {
    .gallery-wrapper {
        background-color: $dark-green;
        .container-row{
            max-width: 1400px;
            margin: 2em auto 0 auto;
            padding: 0 1em;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include media-query(micro) {
              flex-direction: row;
              padding: 2em 1em;
            }

            .gallery-text {
                flex: 1 1 auto;
                align-self: center;
                display: inline-block;
                width: 100%;

                @include media-query(micro) {
                  width: 29%;
                  align-self: normal;
                  margin-right: 3em;
              }
            }
            .image-container {
                flex: 1 1 auto;
                align-self: center;
                display: inline-block;
                width: 100%;

                @include media-query(micro) {
                   width: 59%;
                }

                .main-gallery {
                  display: flex;
                  flex-direction: column;
                  flex-wrap: nowrap;

                  @include media-query(micro) {
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                  }
                    .set {
                        flex: 1 1 auto;
                        position: relative;
                        margin-bottom: 1em;

                        @include media-query(micro) {
                          max-width: 45%;
                          margin: 0 1em 1em 0;
                        }

                        &:nth-child(odd) {
                          h3 {
                            background-color: rgba(0, 0, 0, 0.675);
                          }
                        }

                        &:nth-child(even) {
                          h3 {
                            background-color: rgba(51,166,67, 0.675);
                          }
                        }

                      a {
                        text-decoration: none;

                        &:hover {
                          p {
                            color: $light-green;
                          }
                        }
                          img {
                              width: 100%;
                          }

                          h3 {
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            font-size: 1em;
                            text-align: center;
                            padding: 0.3em 0;
                          }
                        }
                    }
                    .box {
                        flex: 1 1 auto;
                        position: relative;
                        margin-bottom: 1em;

                        @include media-query(micro) {
                          max-width: 45%;
                          margin: 0 1em 1em 0;
                        }

                        &:nth-child(odd) {
                          h3 {
                            background-color: rgba(0, 0, 0, 0.675);
                          }
                        }

                        &:nth-child(even) {
                          h3 {
                            background-color: rgba(51,166,67, 0.675);
                          }
                        }

                      a {
                        text-decoration: none;
                        display: block;

                        &:hover {
                          p {
                            color: $light-green;
                          }
                        }
                          img {
                              width: 100%;
                          }

                          h3 {
                            position: absolute;
                            bottom: 31px;
                            width: 100%;
                            font-size: 1em;
                            text-align: center;
                            padding: 0.3em 0;
                          }
                        }
                    }
                }
            }
        }
    }
}
