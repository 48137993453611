.gallery-bar{
    width: 100%;

    .container-row{
        max-width: 1400px;
        margin: 0 auto;
        position: relative;

        h2{
            color: #000;
            text-align: center;
        }

        p{
            color: #000;
            text-align: center;
        }

        .image-container{
            flex-direction: column;
            flex-wrap: wrap;
            display: flex;
            justify-content: center;

            @include media-query(micro){
                flex-direction: row;
                justify-content: center
            }

            @include media-query(normal){
                flex-wrap: nowrap;
            }

            .mini-col{
                flex: 0 1 auto;
                align-self: center;
                display: flex;

                @include media-query(nano){
                    flex: 0 1 auto;
                    justify-content: space-between;
                }

                @include media-query(micro){
                    display: inline;
                }

                @include media-query(milli){
                    flex: 0 1 14em;
                    padding-right: 1em;
                }

                @include media-query(normal){
                    flex: 1 1 14em;
                }

                a{
                    margin: auto;

                    &:nth-child(odd){
                        padding-right: 0.5em;

                        @include media-query(normal){
                            padding-right: 0em;
                        }
                    }

                    &:nth-child(even){

                        img{
                            @include media-query(normal){
                                padding-top: 0.5em;
                            }
                        }
                    }
                }
            }

            .big-col{
                flex: 0 1 auto;
                display: flex;
                justify-content: center;

                @include media-query(normal){
                    flex: 1 1 auto;
                    padding-right: 1em;
                }
            }
        }
    }
}
