.single-supp-bar {
    background-color: #339933;
    .container-row {
        display: flex;
        flex-direction: column;

        @include media-query(kilo) {
            flex-direction: row;
        }
        .supp-left {

            @include media-query(kilo) {
                width: 48%;
                align-self: center;
            }

            img {
                max-width: 100%;

                @include media-query(micro) {
                    max-width: 50%;
                }
            }
            p {
                text-align: center;

                @include media-query(normal) {
                    text-align: left;
                }
            }
        }
        .supp-right {

            @include media-query(micro) {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }

            @include media-query(kilo) {
                width: 48%;
                margin-left: auto;
            }

            .supp-right-container {
                display: flex;
                flex-direction: column;

                @include media-query(micro) {
                    width: 48%;
                    margin-right: 0.5em;
                }

                .supp-link {
                    background-color: #336633;
                    display: inline-block;
                    padding: 1em;
                    margin-bottom: 0.5em;
                    text-decoration: none;

                    &:hover {
                        background-color: lighten(#336633, 10%);
                    }

                    @include media-query(micro) {
                        flex-basis: 100%;
                    }

                    span {
                        font-weight: 600;
                    }

                    p{
                        margin: 0;
                    }
                }

                img {
                    margin: 0 auto 0.5em auto;
                    display: block;

                    @include media-query(micro) {
                        margin: 0;
                        width: 100%;
                        flex-basis: 100%;
                    }
                }
                }
                img {
                    margin: 0 auto;
                    display: block;

                    @include media-query(micro) {
                        margin: 0;
                        width: 45%;
                        flex-basis: 45%;
                    }


                }
            }
        }
    }
